import React from "react";
import Title from "../title/Title";

export default function Home() {
	return (
		<div className="home">
			<Title />
			<div></div>
		</div>
	);
}
