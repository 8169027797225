import React from "react";

export default function Documentation() {
	return (
		<div className="mainBody">
			<p>
				This is the documentation page... Check back later, we're still
				working.
			</p>
		</div>
	);
}
